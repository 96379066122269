@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/roboto-regular.woff2') format('woff2'),
    url('assets/fonts/roboto-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/roboto-medium.woff2') format('woff2'),
    url('assets/fonts/roboto-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/roboto-bold.woff2') format('woff2'),
    url('assets/fonts/roboto-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('assets/fonts/gilroy-regular.woff2') format('woff2'),
    url('assets/fonts/gilroy-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('assets/fonts/gilroy-bold.woff2') format('woff2'),
    url('assets/fonts/gilroy-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

ul[class] {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
  margin: 0;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #1f2041;
  background-color: #faf9fa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}
